<script>
import { component as embedComponentsMixin } from '@shared/mixins/embeds';
import utilsMixin from '@shared/mixins/utils';
import TrainingItem from '@school/components/TrainingItem.vue';

export default {
  mixins: [embedComponentsMixin, utilsMixin],
  components: { TrainingItem },
};
</script>

<template>
  <div class="px-1 pt-1 pb-2">
    <TrainingItem
      :style="{
        width: params['style[width]'],
        height: 'auto',
        minHeight: params['style[height]'] || 'auto',
        maxWidth: '100%',
      }"
      :trainingName="text"
      :training="buyable"
      :isEmbed="true"
      @img-load="$emit('load')"
    />
  </div>
</template>
