<script>
import get from 'lodash/get';
import embedsMixin from '@shared/mixins/embeds';
import EmbedCard from '@school/components/EmbedCard.vue';

export default {
  mixins: [embedsMixin],
  components: { EmbedCard },
  data: () => ({
    training: get(window, 'initialState.training', null),
    autoPostDimensions: false,
  }),
};
</script>

<template>
  <EmbedCard @load="postDimensions" :params="$route.query" :buyable="training" />
</template>
